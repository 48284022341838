<template>
    <b-row>
        <b-col>
            <b-card>
                <b-tabs pills>
                    <b-tab title="Detail" active>
                        <b-row>
                            <b-col md="6" xl="4">
                                <b-form-group label="NIP" label-for="nip">
                                    <b-form-input id="nip" v-model="form.nip" required />
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4">
                                <b-form-group label="NIK" label-for="nik">
                                    <b-form-input id="nik" v-model="form.nik" type="number" onKeyPress="if(this.value.length==16) return false;" required />
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4">
                                <b-form-group label="Name" label-for="name">
                                    <b-form-input id="name" v-model="form.name" required />
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4">
                                <b-form-group label="Email" label-for="email">
                                    <b-form-input id="email" v-model="form.email" required />
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4">
                                <b-form-group label="Birthplace" label-for="birthplace">
                                    <b-form-input id="birthplace" v-model="form.birthplace" required />
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4">
                                <b-form-group label="Birthdate" label-for="birthdate">
                                    <flat-pickr
                                    id="birthdate"
                                    v-model="form.birthdate"
                                    class="form-control"
                                    :config="{ dateFormat: 'd-m-Y'}"
                                    required
                                    />
                                    <!-- <p>Value: '{{ form.birthdate }}'</p> -->
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4">
                                <b-form-group label="Gender" label-for="gender">
                                    <v-select
                                    id="gender"
                                    v-model="selectedGender"
                                    :options="optionsGender"
                                    label="text"
                                    required
                                    />
                                    <!-- Selected: <strong>{{ selectedGender }}</strong> -->
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4">
                                <b-form-group label="Contact" label-for="contact">
                                    <b-form-input id="contact" v-model="form.contact" type="number" onKeyPress="if(this.value.length==13) return false;" required />
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4">
                                <b-form-group label="Organization" label-for="organization">
                                    <v-select
                                    id="organization"
                                    v-model="selectedOrganization"
                                    :options="optionsOrganization"
                                    label="text"
                                    required
                                    />
                                    <!-- Selected: <strong>{{ selectedOrganization }}</strong> -->
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4">
                                <b-form-group label="Division" label-for="division">
                                    <v-select
                                    id="division"
                                    v-model="selectedDivision"
                                    :options="optionsDivision"
                                    label="text"
                                    required
                                    />
                                    <!-- Selected: <strong>{{ selectedDivision }}</strong> -->
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4">
                                <b-form-group label="Department" label-for="department">
                                    <v-select
                                    id="department"
                                    v-model="selectedDepartment"
                                    :options="optionsDepartment"
                                    label="text"
                                    required
                                    />
                                    <!-- Selected: <strong>{{ selectedDepartment }}</strong> -->
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4">
                                <b-form-group label="Position" label-for="Position">
                                    <v-select
                                    id="Position"
                                    v-model="selectedPosition"
                                    :options="optionsPosition"
                                    label="text"
                                    required
                                    />
                                    <!-- Selected: <strong>{{ selectedPosition }}</strong> -->
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4">
                                <b-form-group label="Manager" label-for="manager">
                                    <v-select
                                    id="manager"
                                    v-model="selectedManager"
                                    :options="optionsManager"
                                    label="text"
                                    required
                                    />
                                    <!-- Selected: <strong>{{ selectedManager }}</strong> -->
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4">
                                <b-form-group label="Start At" label-for="starts">
                                    <flat-pickr
                                    id="starts"
                                    v-model="form.start_at"
                                    class="form-control"
                                    :config="{ dateFormat: 'd-m-Y'}"
                                    required
                                    />
                                    <!-- Selected: <strong>{{ form.start_at }}</strong> -->
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4">
                                <b-form-group label="End At" label-for="ends">
                                    <flat-pickr
                                    id="ends"
                                    v-model="form.end_at"
                                    class="form-control"
                                    :config="{ dateFormat: 'd-m-Y'}"
                                    required
                                    />
                                    <!-- Selected: <strong>{{ form.start_at }}</strong> -->
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4">
                                <b-form-group label="Employee Status" label-for="status_type">
                                    <v-select
                                    id="status_type"
                                    v-model="form.selectedStatus"
                                    :options="form.optionsStatus"
                                    label="text"
                                    required
                                    />
                                    <!-- Selected: <strong>{{ form.selectedStatus }}</strong> -->
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4">
                                <b-form-group label="Status" label-for="status">
                                    <v-select
                                    id="status"
                                    v-model="selectedStatus"
                                    :options="optionsStatus"
                                    label="text"
                                    required
                                    />
                                    <!-- Selected: <strong>{{ selectedStatus }}</strong> -->
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4">
                                <b-form-group label="Role" label-for="role">
                                    <v-select
                                    id="role"
                                    v-model="selectedRole"
                                    :options="optionsRole"
                                    label="text"
                                    required
                                    />
                                    <!-- Selected: <strong>{{ selectedRole }}</strong> -->
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab title="Address">
                        <b-row>
                            <b-col md="12" xl="12">
                                <b-form-group label="Adress" label-for="address">
                                    <b-form-textarea
                                        id="address"
                                        v-model="form2.address"
                                        required
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="4" xl="4">
                                <b-form-group label="Postal Code" label-for="postal_code">
                                    <b-form-input
                                        id="postal_code"
                                        v-model="form2.postal_code"
                                        required
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="4" xl="4">
                                <b-form-group label="Village" label-for="village">
                                    <b-form-input
                                        id="village"
                                        v-model="form2.village"
                                        required
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="4" xl="4">
                                <b-form-group label="District" label-for="district">
                                    <b-form-input
                                        id="district"
                                        v-model="form2.district"
                                        required
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="4" xl="4">
                                <b-form-group label="City" label-for="city">
                                    <b-form-input
                                        id="city"
                                        v-model="form2.city"
                                        required
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="4" xl="4">
                                <b-form-group label="Province" label-for="province">
                                    <b-form-input
                                        id="province"
                                        v-model="form2.province"
                                        required
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab title="Tax">
                        <b-row>
                            <b-col md="6" xl="4">
                                <b-form-group label="Had PPH" label-for="had_pph">
                                    <v-select
                                    id="had_pph"
                                    v-model="form3.selectedPPH"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="form3.optionsPPH"
                                    label="text"
                                    required
                                    />
                                    <!-- Selected: <strong>{{ form3.selectedPPH }}</strong> -->
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4" v-if="form3.triggerPPH === 1">
                                <b-form-group label="NPWP" label-for="npwp">
                                    <b-form-input
                                        id="npwp"
                                        v-model="form3.npwp"
                                        required
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4" v-if="form3.triggerPPH === 1">
                                <b-form-group label="Had PTKP" label-for="had_ptkp">
                                    <v-select
                                    id="had_ptkp"
                                    v-model="form3.selectedPTKP"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="form3.optionsPTKP"
                                    label="text"
                                    required
                                    />
                                    <!-- Selected: <strong>{{ form3.selectedPTKP }}</strong> -->
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4" v-if="form3.triggerPPH === 1 && form3.triggerPTKP === 1">
                                <b-form-group label="PTKP" label-for="ptkp">
                                    <v-select
                                    id="ptkp"
                                    v-model="form3.selectedValuePTKP"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="form3.optionsValuePTKP"
                                    label="text"
                                    required
                                    />
                                    <!-- Selected: <strong>{{ form3.selectedValuePTKP }}</strong> -->
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <hr class="my-1" v-if="form3.triggerPPH === 1">
                        <span class="font-weight-bolder" v-if="form3.triggerPPH === 1">Last Tax</span>
                        <b-row class="mt-1">
                            <b-col md="6" xl="4" v-if="form3.triggerPPH === 1">
                                <b-form-group label="Start At" label-for="start_at">
                                    <flat-pickr
                                    id="start_at"
                                    v-model="form3.start_at"
                                    class="form-control"
                                    :config="{ dateFormat: 'd-m-Y'}"
                                    required
                                    />
                                    <!-- <p>Value: '{{ form3.start_at }}'</p> -->
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4" v-if="form3.triggerPPH === 1">
                                <b-form-group label="Last Sallary" label-for="last_sallary">
                                    <cleave
                                        id="last_sallary"
                                        placeholder="Last Sallary"
                                        v-model="form3.last_sallary"
                                        class="form-control"
                                        :raw="false"
                                        :options="options.number"
                                        required
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4" v-if="form3.triggerPPH === 1">
                                <b-form-group label="Last PPH" label-for="last_pph">
                                    <cleave
                                        id="last_pph"
                                        placeholder="Last PPH"
                                        v-model="form3.last_pph"
                                        class="form-control"
                                        :raw="false"
                                        :options="options.number"
                                        required
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab title="Bank">
                        <b-row>
                            <b-col md="6" xl="4">
                                <b-form-group label="Bank Name" label-for="bank_name">
                                    <b-form-input
                                        id="bank_name"
                                        v-model="form4.bank_name"
                                        required
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4">
                                <b-form-group label="Account No" label-for="account_no">
                                    <b-form-input
                                        id="account_no"
                                        v-model="form4.account_no"
                                        type="number"
                                        required
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="6" xl="4">
                                <b-form-group label="Account Name" label-for="account_name">
                                    <b-form-input
                                        id="account_name"
                                        v-model="form4.account_name"
                                        required
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-tab>
                </b-tabs>
            </b-card>
        </b-col>
        <!-- submit and reset -->
        <b-col md="12">
            <b-row>
                <b-col>
                    <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    class="mr-1"
                    @click="updateDetails()"
                    block
                    >
                    Update
                    </b-button>
                </b-col>
                <b-col>
                    <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="danger"
                    class="mr-1"
                    @click="deleteEmployees()"
                    block
                    >
                    Delete
                    </b-button>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
import {
    BFormTextarea, BTab, BTabs, BAlert, BLink, BFormInvalidFeedback, BFormValidFeedback, BFormRadio, BFormDatepicker, BFormSelect, BListGroupItem, BAvatar, BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
import Prism from 'vue-prism-component'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'

export default {
    components: {
        ToastificationContent,
        Cleave,
        flatPickr,
        vSelect,
        BFormTextarea,
        BTab,
        BTabs,
        BAlert,
        BLink,
        BFormInvalidFeedback,
        BFormValidFeedback,
        BFormRadio,
        BFormDatepicker,
        BFormSelect,
        BListGroupItem,
        BAvatar,
        BCard,
        BCardText,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BForm,
        BButton,
        draggable,
        Prism
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            id: null,
            form: {
                nip: '',
                nik: null,
                name: '',
                birthplace: '',
                birthdate: null,
                email: '',
                gender: '',
                contact: null,
                start_at: null,
                end_at: null,
                selectedStatus: null,
                optionsStatus: []
            },
            form2: {
                address: '',
                postal_code: '',
                village: '',
                district: '',
                city: '',
                province: '',
                country: 'Indonesia'
            },
            form3: {
                selectedPPH: null,
                optionsPPH: [
                    { value: null, text: 'Please select'},
                    { value: 1, text: 'Yes'},
                    { value: 0, text: 'No'}
                ],
                triggerPPH: null,
                npwp: null,
                selectedPTKP: null,
                optionsPTKP: [
                    { value: null, text: 'Please select'},
                    { value: 1, text: 'Yes'},
                    { value: 0, text: 'No'}
                ],
                triggerPTKP: null,
                selectedValuePTKP: null,
                optionsValuePTKP: [],
                start_at: '',
                last_sallary: '',
                last_pph: ''
            },
            form4: {
                bank_name: '',
                account_no: '',
                account_name: ''
            },
            selectedGender: null,
            optionsGender: [
                { value: null, text: 'Please select gender'},
                { value: 'Pria', text: 'Pria'},
                { value: 'Wanita', text: 'Wanita'}
            ],
            selectedOrganization: null,
            optionsOrganization: [],
            selectedDivision: null,
            optionsDivision: [],
            selectedDepartment: null,
            optionsDepartment: [],
            selectedPosition: null,
            optionsPosition: [],
            selectedManager: null,
            optionsManager: [],
            selectedRole: null,
            optionsRole: [],
            selectedStatus: null,
            optionsStatus: [
                { value: null, text: 'Please select status'},
                { value: 1, text: 'Active'},
                { value: 0, text: 'Non-Active'}
            ],
            options: {
                number: {
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand',
                },
            },
            eventOrganization: null,
            eventDivision: null,
            errMessage: ''
        }
    },
    watch: {
        selectedOrganization: function (data) {
            if(data.value === this.eventOrganization.value) {
                this.selectedOrganization = this.eventOrganization
            } else {
                this.eventOrganization = data
                this.trigger(data.value)
            }
        },
        selectedDivision: function (data) {
            if(data.value === this.eventDivision.value) {
                this.selectedDivision = this.eventDivision
            } else {
                this.eventDivision = data
                this.trigger2(data.value)
            }
        },
        'form3.selectedPPH'(data) {
            this.form3.triggerPPH = data.value
        },
        'form3.selectedPTKP': function (data) {
            this.form3.triggerPTKP = data.value
        },
    },
    created() {
        this.getPTKP()
        this.getDetails()
        this.getOrganization()
        this.getPosition()
        this.getManager()
        this.getRoles()
        this.getEmployeeStatus()
    },
    methods: {
        getDetails() {
            const id = this.$route.params.id
            this.$http
            .get('employees/' +id)
            .then((response) => {
                console.log(response.data.data)
                this.id = response.data.data.id
                this.form.nip = response.data.data.nip
                this.form.nik = response.data.data.nik
                this.form.name = response.data.data.name
                this.form.email = response.data.data.email
                this.form.birthplace = response.data.data.birthplace
                this.form.birthdate = response.data.data.birthdate
                this.selectedGender = {'value': response.data.data.gender, 'text': response.data.data.gender}
                this.form.address = response.data.data.address
                this.form.contact = response.data.data.phone
                this.selectedOrganization = {'value': response.data.data.organization, 'text': response.data.data.organization_name}
                //counter watcher
                this.eventOrganization = {'value': response.data.data.organization, 'text': response.data.data.organization_name}
                this.$http.get('divisions/all?organization_id='+response.data.data.organization)
                .then(res => { 
                    // console.log(res.data.data)
                    this.optionsDivision.push({value: null, text: 'Please select 1 division'})
                    
                    var datas = res.data.data
                    console.log
                    var i
                    for(i=0; i<datas.length; i++) {
                        var $data = {
                            value: datas[i].id,
                            text: datas[i].name
                        }
                        this.optionsDivision.push($data)
                    }
                    // console.log(this.optionsDivision)
                });
                this.selectedDivision = {'value': response.data.data.division, 'text': response.data.data.division_name}
                //counter watcher
                this.eventDivision = {'value': response.data.data.division, 'text': response.data.data.division_name}
                this.$http.get('departments/all?division_id='+this.selectedDivision)
                .then(res => { 
                    this.optionsDepartment.push({value: null, text: 'Please select 1 position'})
                    
                    var datas = res.data.data
                    var i
                    for(i=0; i<datas.length; i++) {
                        var $data = {
                            value: datas[i].id,
                            text: datas[i].name
                        }
                        this.optionsDepartment.push($data)
                    }
                    // console.log(this.optionsDepartment)
                });
                this.selectedDepartment = {'value': response.data.data.department, 'text': response.data.data.department_name}
                this.selectedPosition = {'value': response.data.data.position, 'text': response.data.data.position_name}
                if(response.data.data.manager_id === 0) {
                    this.selectedManager =  {'value': null, 'text': 'Optional select manager'}
                } else {
                    this.selectedManager = {'value': response.data.data.manager_id, 'text': response.data.data.manager_name}
                }
                this.form.start_at = response.data.data.start_at
                this.form.end_at = response.data.data.end_at
                this.selectedRole = {'value': response.data.data.role_id, 'text': response.data.data.role_name}
                if(response.data.data.status === 1) {
                    this.selectedStatus = {'value': 1, 'text': 'Active'}
                } else {
                    this.selectedStatus = {'value': 0, 'text': 'Non-Active'}
                }
                this.form.selectedStatus = {'value': response.data.data.status_type, 'text': response.data.data.status_type_name}

                this.form2.address = response.data.data.has_address.address
                this.form2.postal_code = response.data.data.has_address.postal_code
                this.form2.village = response.data.data.has_address.village
                this.form2.district = response.data.data.has_address.district
                this.form2.city = response.data.data.has_address.city
                this.form2.province = response.data.data.has_address.province
                this.form2.country = response.data.data.has_address.country
                
                if(response.data.data.has_tax === null) {
                    this.form3.selectedPPH = { 'value': null, 'text': 'Please select'}
                } else if(response.data.data.has_tax.had_pph === 1) {
                    this.form3.selectedPPH = {'value': 1, 'text': 'Yes'}
                } else {
                    this.form3.selectedPPH = {'value': 0, 'text': 'No'}
                }
                if(response.data.data.has_tax === null) {
                    this.form3.npwp = null
                } else {
                    this.form3.npwp = response.data.data.has_tax.npwp
                }
                if(response.data.data.has_tax === null) {
                    this.form3.selectedPTKP = { 'value': null, 'text': 'Please select'}
                } else if(response.data.data.has_tax.had_ptkp === 1) {
                    this.form3.selectedPTKP = {'value': 1, 'text': 'Yes'}
                } else {
                    this.form3.selectedPTKP = {'value': 0, 'text': 'No'}
                }
                if(response.data.data.has_tax === null) {
                    this.form3.selectedValuePTKP = null
                } else {
                    this.form3.selectedValuePTKP = {'value': response.data.data.has_tax.ptkp, 'text': response.data.data.has_tax.has_ptkp.name}
                }
                
                this.form3.start_at = response.data.data.has_tax.start_at
                this.form3.last_sallary = response.data.data.has_tax.last_sallary
                this.form3.last_pph = response.data.data.has_tax.last_pph

                this.form4.bank_name = response.data.data.has_bank.bank_name
                this.form4.account_no = response.data.data.has_bank.account_no
                this.form4.account_name = response.data.data.has_bank.account_name
            }).catch((err) => {
                console.log(err.response)
            });
        },
        getPTKP() {
            this.$http.get('employee/ptkp')
            .then(res => { 
                this.form3.optionsValuePTKP.push({value: null, text: 'Please select 1 PTKP'})
                
                var datas = res.data.data
                var i
                for(i=0; i<datas.length; i++) {
                    var $data = {
                        value: datas[i].id,
                        text: datas[i].name
                    }
                    this.form3.optionsValuePTKP.push($data)
                }
                // console.log(this.form3.optionsValuePTKP)
            });
        },
        getOrganization() {
            this.$http.get('organizations')
            .then(res => { 
                this.optionsOrganization.push({value: null, text: 'Please select 1 organization'})
                
                var datas = res.data.data
                var i
                for(i=0; i<datas.length; i++) {
                    var $data = {
                        value: datas[i].id,
                        text: datas[i].name
                    }
                    this.optionsOrganization.push($data)
                }
                // console.log(this.optionsOrganization)
            });
        },
        getPosition() {
            this.$http.get('positions')
            .then(res => { 
                this.optionsPosition.push({value: null, text: 'Please select 1 position'})
                
                var datas = res.data.data
                var i
                for(i=0; i<datas.length; i++) {
                    var $data = {
                        value: datas[i].id,
                        text: datas[i].name
                    }
                    this.optionsPosition.push($data)
                }
                // console.log(this.optionsPosition)
            });
        },
        getManager() {
            this.$http.get('employees')
            .then(res => { 
                this.optionsManager.push({value: null, text: 'Optional select manager'})
                
                var datas = res.data.data
                var i
                for(i=0; i<datas.length; i++) {
                    var $data = {
                        value: datas[i].id,
                        text: datas[i].name
                    }
                    this.optionsManager.push($data)
                }
                // console.log(this.optionsManager)
            });
        },
        getRoles() {
            this.$http.get('user/roles')
            .then(res => { 
                this.optionsRole.push({value: null, text: 'Please select role'})
                
                var datas = res.data.data
                var i
                for(i=0; i<datas.length; i++) {
                    var $data = {
                        value: datas[i].id,
                        text: datas[i].name
                    }
                    this.optionsRole.push($data)
                }
                // console.log(this.optionsRole)
            });
        },
        getEmployeeStatus() {
            this.$http.get('employee/status')
            .then(response => { 
                this.form.optionsStatus.push({value: null, text: 'Please select employee status'})
                
                var datas = response.data.data
                var i
                for(i=0; i<datas.length; i++) {
                    var $data = {
                        value: datas[i].id,
                        text: datas[i].name
                    }
                    this.form.optionsStatus.push($data)
                }
                // console.log(this.form.optionsStatus)
            });
        },
        formatRupiah(money) {
            return new Intl.NumberFormat('id-ID',
                { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
            ).format(money);
        },
        trigger(id) {
            this.$http.get("divisions/all?organization_id="+id)
            .then(res => { 
                this.optionsDivision = []
                this.selectedDivision = null
                this.optionsDivision.push({value: null, text: 'Please select 1 division'})
                
                var datas = res.data.data
                var i
                for(i=0; i<datas.length; i++) {
                    var $data = {
                        value: datas[i].id,
                        text: datas[i].name
                    }
                    this.optionsDivision.push($data)
                }
                // console.log(this.optionsDivision)
            });
        },
        trigger2(id) {
            this.$http.get("departments/all?division_id="+id)
            .then(res => { 
                this.optionsDepartment = [];
                this.selectedDepartment = null;
                this.optionsDepartment.push({value: null, text: 'Please select 1 department'})
                
                var datas = res.data.data
                var i
                for(i=0; i<datas.length; i++) {
                    var $data = {
                        value: datas[i].id,
                        text: datas[i].name
                    }
                    this.optionsDepartment.push($data)
                }
                // console.log(this.optionsDepartment)
            });
        },
        updateDetails() {
            if(this.form.end_at === null) {
                this.form.end_at = null
            } else {
                this.form.end_at = this.form.end_at
            }
            // console.log(this.selectedManager)
            if(this.selectedManager === 0 || this.selectedManager === null) {
                this.selectedManager = {value: 0}
            }
            this.$http
            .post("employees/"+this.id+"/update", {
                "nip": this.form.nip,
                "nik": this.form.nik,
                "name": this.form.name,
                "birthplace": this.form.birthplace,
                "birthdate": this.form.birthdate,
                "email": this.form.email,
                "address": this.form.address,
                "gender": this.selectedGender.value,
                "phone": this.form.contact,
                "organization": this.selectedOrganization.value,
                "division": this.selectedDivision.value,
                "department": this.selectedDepartment.value,
                "position": this.selectedPosition.value,
                "manager_id": this.selectedManager.value,
                "start_at": this.form.start_at,
                "end_at": this.form.end_at,
                "role_id": this.selectedRole.value,
                "status": this.selectedStatus.value,
                "status_type": this.form.selectedStatus.value
            })
            .then((response) => {
                // console.log(response.data.data)
                this.updateAddress()
            }).catch((errors) => {
                this.errMessage = errors.response.data.message
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: this.errMessage,
                        variant: 'warning',
                    },
                })
                console.log(errors.response)
            });
        },
        updateAddress() {
            this.$http
            .post("employee/"+this.id+"/address/update", {
                "address": this.form2.address,
                "postal_code": this.form2.postal_code,
                "village": this.form2.village,
                "district": this.form2.district,
                "city": this.form2.city,
                "province": this.form2.province,
                "country": this.form2.country
            })
            .then((response) => {
                // console.log(response.data.data)
                this.validasi2()
            }).catch((errors) => {
                this.errMessage = errors.response.data.message
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: this.errMessage,
                        variant: 'warning',
                    },
                })
                console.log(errors.response)
            });
        },
        validasi2() {
            if(this.form3.triggerPPH === null) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'The Had PPH field is required',
                        variant: 'warning',
                    },
                })
            } else if(this.form3.triggerPPH === 0) {
                this.updateTax()
            } else if(this.form3.triggerPPH === 1 && this.form3.npwp === null) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'The NPWP field is required',
                        variant: 'warning',
                    },
                })
            } else if(this.form3.triggerPPH === 1 && this.form3.triggerPTKP === null) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'The Had PTKP field is required',
                        variant: 'warning',
                    },
                })
            } else if(this.form3.triggerPTKP === 1 && this.form3.selectedValuePTKP === null) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'The PTKP field is required',
                        variant: 'warning',
                    },
                })
            } else if(this.form3.triggerPPH === 1 && this.form3.start_at === '') {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'The Start At field is required',
                        variant: 'warning',
                    },
                })
            } else if(this.form3.triggerPPH === 1 && this.form3.last_sallary === '') {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'The Last Sallary field is required',
                        variant: 'warning',
                    },
                })
            } else if(this.form3.triggerPPH === 1 && this.form3.last_pph === '') {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'The Last PPH field is required',
                        variant: 'warning',
                    },
                })
            } else {
                this.updateTax()
            }
        },
        updateTax() {
            if(this.form3.triggerPPH === 1) {
                var newLastSallary = this.form3.last_sallary
                newLastSallary = newLastSallary.replace(/\,/g,'')
                var newLastPPH = this.form3.last_pph
                newLastPPH = newLastPPH.replace(/\,/g,'')
                this.$http
                .post("employee/"+this.id+"/tax/update", {
                    "had_pph": this.form3.triggerPPH,
                    "npwp": this.form3.npwp,
                    "had_ptkp": this.form3.triggerPTKP,
                    "ptkp": this.form3.selectedValuePTKP.value,
                    "start_at": this.form3.start_at,
                    "last_sallary": newLastSallary,
                    "last_pph": newLastPPH
                })
                .then((response) => {
                    // console.log(response.data.data)
                    this.updateBank()
                }).catch((errors) => {
                    this.errMessage = errors.response.data.message
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Notification',
                            icon: 'BellIcon',
                            text: this.errMessage,
                            variant: 'warning',
                        },
                    })
                    console.log(errors.response)
                });
            } else {
                this.$http
                .post("employee/"+this.id+"/tax/update", {
                    "had_pph": this.form3.triggerPPH,
                })
                .then((response) => {
                    // console.log(response.data.data)
                    this.updateBank()
                }).catch((errors) => {
                    this.errMessage = errors.response.data.message
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Notification',
                            icon: 'BellIcon',
                            text: this.errMessage,
                            variant: 'warning',
                        },
                    })
                    console.log(errors.response)
                });
            }
        },
        updateBank() {
            this.$http
            .post("employee/"+this.id+"/bank/update", {
                "bank_name": this.form4.bank_name,
                "account_no": this.form4.account_no,
                "account_name": this.form4.account_name
            })
            .then((response) => {
                // console.log(response.data.data)
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'Success update Employee',
                        variant: 'success',
                    },
                })
                location.href = "/employees"
            }).catch((errors) => {
                this.errMessage = errors.response.data.message
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: this.errMessage,
                        variant: 'warning',
                    },
                })
                console.log(errors.response)
            });
        },
        deleteEmployees($id) {
            this.$http
            .post("employees/"+$id+"/delete")
            .then((response) => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'Employee has been Deleted',
                        variant: 'danger',
                    },
                })
                location.href = "/employees"
                // console.log(response.data.data)
            }).catch((errors) => {
                this.errMessage = errors.response.data.message
                console.log(errors.response)
            })
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>